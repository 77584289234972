import React, { useState, useEffect } from 'react';
import logo from '../../images/glocybs-logo-bgrem.png';
import Services from './Services';
import Biqua from './Biqua';
import QuantumComputing from './QuantumComputing';
import Contact from './Contact';
import { FaBars, FaTimes } from "react-icons/fa";
import Sidebar from './Sidebar';
import More from './More';

const Navbar = () => {
    const [isServicesHovered, setIsServicesHovered] = useState(false);
    const [isBiquaHovered, setIsBiquaHovered] = useState(false);
    const [isQuantumComputingHovered, setIsQuantumComputingHovered] = useState(false);
    const [isMoreHovered, setIsMoreHovered] = useState(false);
    const [isContactHovered, setIsContactHovered] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const handleServicesMouseEnter = () => {
        setIsServicesHovered(true);
    }
    const handleServicesMouseLeave = () => {
        setIsServicesHovered(false);
    }
    const handleBiquaMouseEnter = () => {
        setIsBiquaHovered(true);
    }
    const handleBiquaMouseLeave = () => {
        setIsBiquaHovered(false);
    }
    const handleQuantumComputingMouseEnter = () => {
        setIsQuantumComputingHovered(true);
    }
    const handleQuantumComputingMouseLeave = () => {
        setIsQuantumComputingHovered(false);
    }
    const handleMoreMouseEnter = () => {
        setIsMoreHovered(true);
    }
    const handleMoreMouseLeave = () => {
        setIsMoreHovered(false);
    }
    const handleContactMouseEnter = () => {
        setIsContactHovered(true);
    }
    const handleContactMouseLeave = () => {
        setIsContactHovered(false);
    }

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='flex justify-center w-[100vw]'>
            <div className={`fixed top-0 left-0 right-0 z-[100] flex justify-center transition-all duration-300 ${isScrolled ? 'bg-white text-black shadow-md' : 'bg-transparent text-white'}`}>
                <div className='flex flex-col'>
                    <nav className="relative container p-2 py-3 flex justify-evenly z-[100] w-[100vw]">
                        <div className='flex items-center font-extrabold text-[23px] mr-8'>
                            <img src={logo} alt="G-QBS" className="h-10 pr-2" />GLO<p className='text-cyan-500'>CYBS</p>
                        </div>
                        <div className="flex-1 hidden md:flex justify-center">
                            <ul className="flex space-x-6">
                                <div className="flex items-center space-x-10">
                                    <div
                                        className="relative"
                                        onMouseEnter={handleServicesMouseEnter}
                                        onMouseLeave={handleServicesMouseLeave}
                                    >
                                        <li>
                                            <a href="https://www.google.com" className="hover:text-gray-400">
                                                Services
                                            </a>
                                        </li>
                                        {isServicesHovered && (
                                            <div className="absolute top-full left-0 z-20">
                                                <Services />
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className="relative"
                                        onMouseEnter={handleBiquaMouseEnter}
                                        onMouseLeave={handleBiquaMouseLeave}
                                    >
                                        <li>
                                            <a href="https://www.google.com" className="hover:text-gray-400">
                                                BIQUA
                                            </a>
                                        </li>
                                        {isBiquaHovered && (
                                            <div className="absolute top-full left-0 z-50">
                                                <Biqua />
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className="relative"
                                        onMouseEnter={handleQuantumComputingMouseEnter}
                                        onMouseLeave={handleQuantumComputingMouseLeave}
                                    >
                                        <li>
                                            <a href="https://www.google.com" className="hover:text-gray-400">
                                                Quantum Computing
                                            </a>
                                        </li>
                                        {isQuantumComputingHovered && (
                                            <div className="absolute top-full left-0 z-50">
                                                <QuantumComputing />
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className="relative"
                                        onMouseEnter={handleMoreMouseEnter}
                                        onMouseLeave={handleMoreMouseLeave}
                                    >
                                        <li>
                                            <a href="https://www.google.com" className="hover:text-gray-400">
                                                More
                                            </a>
                                        </li>
                                        {isMoreHovered && (
                                            <div className="absolute top-full left-0 z-50">
                                                <More />
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className="relative"
                                        onMouseEnter={handleContactMouseEnter}
                                        onMouseLeave={handleContactMouseLeave}
                                    >
                                        <li>
                                            <a href="https://www.google.com" className="hover:text-gray-400">
                                                Contact
                                            </a>
                                        </li>
                                        {isContactHovered && (
                                            <div className="absolute top-full left-0 z-50">
                                                <Contact />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </ul>
                        </div>
                        <div className='flex flex-row-reverse items-center'>
                            <div className="md:hidden flex items-start justify-center">
                                <button
                                    onClick={toggleSidebar}
                                    className={`text-2xl`}
                                >
                                    {!isSidebarOpen && <FaBars />}
                                    {isSidebarOpen && <FaTimes />}
                                </button>
                            </div>
                            <button className="bg-gradient-to-r text-[11px] md:text-lg from-purple-400 via-pink-500 to-orange-600 text-white md:font-bold p-2 mx-8 rounded-md transition duration-300 justify-end">
    <a href="https://g-qbs-e6080.web.app/" target="_blank" rel="noopener noreferrer" className="block w-full h-full">
        G-QBS Lab
    </a>
</button>

                        </div>
                    </nav>
                    <div className="bg-blue-900 w-full h-1"></div>
                </div>
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            </div>
        </div>
    );
}

export default Navbar;
