import React from 'react';

const Biqua = ({ onMouseEnter, onMouseLeave }) => {
    return (
        <div className="md:absolute md:z-50 md:bg-white md:p-6 md:rounded-lg md:shadow-lg md:w-[1000px] md:-left-56" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className="grid grid-col-1 md:grid-cols-4 gap-4 text-left text-black space-x-4">

                {/* <!-- Blockchain Section --> */}
                <div>
                    <h3 className="text-red-500 font-bold border-b-4 border-blue-500 pb-2">Blockchain</h3>
                    <p className="text-sm text-left mt-[10%]">Allows for the verification of data without revealing the data itself, enhancing privacy.</p>
                    <ul className="mt-4 space-y-5 font-bold">
                        <li><a href="https://www.google.com">Consensus Mechanisms</a></li>
                        <li><a href="https://www.google.com">Smart Contract Security</a></li>
                        <li><a href="https://www.google.com">Encryption and Privacy</a></li>
                        <li><a href="https://www.google.com">Secure Key Management</a></li>
                        <li><a href="https://www.google.com">Network Security</a></li>
                    </ul>
                </div>

                {/* <!-- Innovation Section --> */}
                <div>
                    <h3 className="text-red-500 font-bold border-b-4 border-blue-500 pb-2">Innovation</h3>
                    <p className="text-sm text-left mt-[10%]">AI-driven systems can learn and recognize normal user behavior patterns and detect anomalies that may indicate a security breach.</p>
                    <ul className="mt-4 space-y-5 font-bold">
                        <li><a href="https://www.google.com">Behavioural Analysis</a></li>
                        <li><a href="https://www.google.com">Post-Quantum Algorithms</a></li>
                        <li><a href="https://www.google.com">Decentralized Autonomous Organizations (DAOs)</a></li>
                        <li><a href="https://www.google.com">User and Entity Behaviour Analytics (UEBA)</a></li>
                    </ul>
                </div>

                {/* <!-- Quantum Computing Section --> */}
                <div>
                    <h3 className="text-red-500 font-bold border-b-4 border-blue-500 pb-2">Quantum Commputing</h3>
                    <p className="text-sm text-left mt-[10%]">Research and develop a quantum internet infrastructure for ultra-secure communication channels using quantum entanglement.</p>
                    <ul className="mt-4 space-y-5 font-bold">
                        <li><a href="https://www.google.com">Quantum-Resistant Encryption</a></li>
                        <li><a href="https://www.google.com">Quantum-Secure Networks</a></li>
                        <li><a href="https://www.google.com">Advanced Threat Detection</a></li>
                        <li><a href="https://www.google.com">Optimization of Security Protocols</a></li>
                        <li><a href="https://www.google.com">Secure Data Storage</a></li>
                    </ul>
                </div>

                {/* <!-- Artificial intelligence Section --> */}
                <div>
                    <h3 className="text-red-500 font-bold border-b-4 border-blue-500 pb-2">Artificial intelligence</h3>
                    <p className="text-sm text-left mt-[10%]">AI can improve the accuracy and security of biometric authentication methods, such as facial recognition, fingerprint scanning, and voice recognition, reducing the risk of unauthorized access.</p>
                    <ul className="mt-4 space-y-5 font-bold">
                        <li><a href="https://www.google.com">Enhanced Access Control and Authentication</a></li>
                        <li><a href="https://www.google.com">Fraud Detection and Prevention</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Biqua;