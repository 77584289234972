import React from "react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import Services from "./Services";
import Biqua from "./Biqua";
import QuantumComputing from "./QuantumComputing";
import More from "./More";
import Contact from "./Contact";

const Sidebar = ({ isOpen, toggleSidebar }) => {

    const [isServicesOpen, setIsServicesOpen] = React.useState(false);
    const [isBiquaOpen, setIsBiquaOpen] = React.useState(false);
    const [isQuantumComputingOpen, setIsQuantumComputingOpen] = React.useState(false);
    const [isMoreOpen, setIsMoreOpen] = React.useState(false);
    const [isContactOpen, setIsContactOpen] = React.useState(false);

    const toggleServices = () => {
        setIsServicesOpen(!isServicesOpen);
    }
    const toggleBiqua = () => {
        setIsBiquaOpen(!isBiquaOpen);
    }
    const toggleQuantumComputing = () => {
        setIsQuantumComputingOpen(!isQuantumComputingOpen);
    }
    const toggleMore = () => {
        setIsMoreOpen(!isMoreOpen);
    }
    const toggleContact = () => {
        setIsContactOpen(!isContactOpen);
    }

    return (
        <div className={`fixed inset-0 mt-16 bg-white z-[60] flex flex-col overflow-y-auto transition-transform duration-300 ${isOpen ? "translate-x-0" : "translate-x-full"}`}>
            <div className="flex flex-col p-4">
                {/* Services */}
                <div className="relative my-4">
                    <a href="https://www.google.com" className="flex justify-between items-center text-black text-2xl pb-4 w-full pl-4">
                        Services
                        <button onClick={toggleServices} className="text-2xl">
                            {isServicesOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </button>
                    </a>
                    <div className={`absolute left-0 right-0 bottom-0 h-0.5 bg-gradient-to-r from-green-500 to-yellow-500`} />
                </div>
                {isServicesOpen && <Services />}

                {/* BIQUA */}
                <div className="relative my-4">
                    <a href="https://www.google.com" className="flex justify-between items-center text-black text-2xl pb-4 w-full pl-4">
                        BIQUA
                        <button onClick={toggleBiqua} className="text-2xl">
                            {isBiquaOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </button>
                    </a>
                    <div className={`absolute left-0 right-0 bottom-0 h-0.5 bg-gradient-to-r from-green-500 to-yellow-500`} />
                </div>
                {isBiquaOpen && <Biqua />}

                {/* Quantum Computing */}
                <div className="relative my-4">
                    <a href="https://www.google.com" className="flex justify-between items-center text-black text-2xl pb-4 w-full pl-4">
                        Quantum Computing
                        <button onClick={toggleQuantumComputing} className="text-2xl">
                            {isQuantumComputingOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </button>
                    </a>
                    <div className={`absolute left-0 right-0 bottom-0 h-0.5 bg-gradient-to-r from-green-500 to-yellow-500`} />
                </div>
                {isQuantumComputingOpen && <QuantumComputing />}

                {/* More */}
                <div className="relative my-4">
                    <a href="https://www.google.com" className="flex justify-between items-center text-black text-2xl pb-4 w-full pl-4">
                        More
                        <button onClick={toggleMore} className="text-2xl">
                            {isMoreOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </button>
                    </a>
                    <div className={`absolute left-0 right-0 bottom-0 h-0.5 bg-gradient-to-r from-green-500 to-yellow-500`} />
                </div>
                {isMoreOpen && <More />}

                {/* Contact */}
                <div className="relative my-4">
                    <a href="https://www.google.com" className="flex justify-between items-center text-black text-2xl pb-4 w-full pl-4">
                        Contact
                        <button onClick={toggleContact} className="text-2xl">
                            {isContactOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </button>
                    </a>
                    <div className={`absolute left-0 right-0 bottom-0 h-0.5 bg-gradient-to-r from-green-500 to-yellow-500`} />
                </div>
                {isContactOpen && <Contact />}
            </div>
        </div>
    );
};

export default Sidebar;