import React from 'react';

const QuantumComputing = ({ onMouseEnter, onMouseLeave }) => {
    return (
        <div className="md:absolute md:z-50 md:bg-white md:p-6 md:rounded-lg md:shadow-lg md:w-[1200px] md:-left-96" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-4 text-left text-black space-x-4">

                {/* <!-- Authentication and Identity Management Section --> */}
                <div>
                    <h3 className="text-red-500 font-bold pb-2">Authentication and Identity Management</h3>
                    <p className='border-b-4 border-blue-500'></p>
                    <p className="text-sm text-left mt-[10%]">Use quantum computing to enhance biometric authentication methods, making them more secure and harder to spoof.</p>
                    <ul className="mt-4 space-y-5 font-bold">
                        <li><a href="https://www.google.com">Quantum Biometrics</a></li>
                        <li><a href="https://www.google.com">Decentralized Identity Verification</a></li>
                    </ul>
                </div>

                {/* <!-- Cryptanalysis Section --> */}
                <div>
                    <h3 className="text-red-500 font-bold pb-2">Cryptanalysis</h3>
                    <p className='border-b-4 border-blue-500 mt-[12%]'></p>
                    <p className="text-sm text-left mt-[10%]">Investigate quantum algorithms, such as Shor's algorithm, to understand their implications for breaking classical cryptographic systems and develop countermeasures.</p>
                    <ul className="mt-4 space-y-5 font-bold">
                        <li><a href="https://www.google.com">Quantum Cryptanalysis Research</a></li>
                        <li><a href="https://www.google.com">Quantum Random Number Generation (QRNG)</a></li>
                    </ul>
                </div>

                {/* <!-- Incident Response and Recovery Section --> */}
                <div>
                    <h3 className="text-red-500 font-bold pb-2">Incident Response and Recovery</h3>
                    <p className='border-b-4 border-blue-500'></p>
                    <p className="text-sm text-left mt-[10%]">Test the resilience of cybersecurity systems against potential quantum attacks to ensure they can withstand future threats.</p>
                    <ul className="mt-4 space-y-5 font-bold">
                        <li><a href="https://www.google.com">Quantum-Enhanced Forensics</a></li>
                        <li><a href="https://www.google.com">Quantum Resilience Testing</a></li>
                    </ul>
                </div>

                {/* <!-- Secure Software Development Section --> */}
                <div>
                    <h3 className="text-red-500 font-bold pb-2">Secure Software Development</h3>
                    <p className='border-b-4 border-blue-500'></p>

                    <p className="text-sm text-left mt-[10%]">Use quantum computing for advanced static and dynamic code analysis to identify vulnerabilities and security flaws in software.</p>
                    <ul className="mt-4 space-y-5 font-bold">
                        <li><a href="https://www.google.com">Quantum-Enhanced Code Analysis</a></li>
                        <li><a href="https://www.google.com">Quantum-Proof Software Design</a></li>
                    </ul>
                </div>

                {/* <!-- Training and Education Section --> */}
                <div>
                    <h3 className="text-red-500 font-bold pb-2">Training and Education</h3>
                    <p className='border-b-4 border-blue-500 mt-[12%]'></p>
                    <p className="text-sm text-left mt-[10%]">Provide specialized training for cybersecurity professionals on quantum computing and its implications for security.</p>
                    <ul className="mt-4 space-y-5 font-bold">
                        <li><a href="https://www.google.com">Quantum Cybersecurity Training</a></li>
                        <li><a href="https://www.google.com">Collaborative Research Initiatives</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default QuantumComputing;