import React from 'react';
import front_img from '../images/main_page_bg.png';
import mobile_front_img from '../images/mobile_main_page_bg.png';

function MainContent() {
    return (
        <div className="w-[100vw] h-[100vh] z-10 bg-cover bg-center"
        style={{
            backgroundImage: `url(${
              window.innerWidth >= 768 ? (window.innerWidth >= 1024 ? front_img : front_img) : mobile_front_img
            })`,
          }}
            >
            <div className="flex text-white h-full px-4 py-8 md:px-0 relative bg-opacity-0">
                <div className="flex flex-col justify-center pt-[90%] space-y-4 w-full md:w-1/2 lg:px-[8%] md:px-16 lg:py-8 md:py-16">
                    <p className="text-orange-500 text-left mt-[3%] pl-1">Be one step ahead of evolving threats</p>
                    <h1 className="text-3xl md:text-6xl font-extrabold text-left pb-[2%]">BIQUA SHIELDING</h1>
                    <h2 className="text-xl md:text-3xl font-bold text-left pb-[3%]">The Next Generation of Data Protection</h2>
                    <div className='flex-grow md:flex-grow-0 space-y-4 md:pt-0'>
                    <p className="block text-left pb-8">Unleash the power of Three. Experience Biqua: The Next-Gen, 24/7 Cyber Security Trio of AI, Blockchain and Quantum by GLOCYBS.</p>
                    <p className="text-left"><span className="inline-block w-2 h-2 mr-4 bg-orange-500 rounded-full"></span>Talk to us</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainContent;
