import React from 'react';

const Contact = ({ onMouseEnter, onMouseLeave }) => {
    return (
        <div className="md:absolute md:z-50 md:bg-white md:p-6 md:rounded-lg md:shadow-lg md:w-[250px] md:font-bold md:-left-20" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {/* <!-- Heading --> */}
            <h2 className="text-red-500 font-bold text-xl mb-2">Contact</h2>

            {/* <!-- Gradient Border --> */}
            <div className="h-1 bg-gradient-to-r from-red-500 to-blue-500 mb-6"></div>

            {/* <!-- Grid Layout --> */}
            <div className="grid grid-cols-1 md:grid-cols-1 gap-4 text-left">

                {/* <!-- Column 1 --> */}
                <div>
                    <ul className="space-y-8">
                        <li className="mb-2"><a href="https://www.google.com" className="text-gray-800 hover:text-red-500">Contact Information</a></li>
                        <li className="mb-2"><a href="https://www.google.com" className="text-gray-800 hover:text-red-500">Contact Form</a></li>
                        <li className="mb-2"><a href="https://www.google.com" className="text-gray-800 hover:text-red-500">FAQ Section</a></li>
                        <li className="mb-2"><a href="https://www.google.com" className="text-gray-800 hover:text-red-500">Feedback and Support</a></li>
                        <li className="mb-2"><a href="https://www.google.com" className="text-gray-800 hover:text-red-500">Privacy and Data Protection Notice</a></li>
                    </ul>
                </div>

            </div>
        </div>

    )
}

export default Contact;