import React from 'react';
import quantum_bg_laptop from '../images/quantum_bg_laptop.png';
import quantum_bg_mobile from '../images/quantum_bg_mobile.png';

function Quantum1() {
    return (
        <div className='w-[100vw] h-[100vh] flex flex-col justify-center items-center'>
            <h1 className='text-lg md:text-2xl lg:text-3xl font-bold h-0 md:h-1/6 py-[2%] text-center md:flex md:justify-center justify-start px-[7%] hidden '>Our mission is to lead the world in quantum innovation by developing advanced quantum computing and Al technologies.</h1>
            <div className='bg-cover bg-center h-full md:h-5/6 w-[100vw] flex justify-start items-start md:items-center'
                style={{
                    backgroundImage: `url(${window.innerWidth >= 768 ? (window.innerWidth >= 1024 ? quantum_bg_laptop : quantum_bg_laptop) : quantum_bg_mobile
                        })`,
                }}
            >
                <div className='flex flex-col w-full md:w-1/2 ml-[7%] text-left'>
                    <div className='md:pt-0 pt-[7%]'>
                        <p className='pt-4 text-xl md:text-5xl font-bold'>Leading The Quantum Revolution</p>
                        <p className='pt-4 text-sm md:text-md hidden md:block'>Featured</p>
                    </div>
                    <div className='md:pt-0 pt-[105%]'>
                        <p className='pt-4 text-xl md:text-3xl'>G-QBS Lab and BIQUA | World's First Quantum Innovation Research Centre</p>
                        <p className='pt-8 hidden md:block'>G-QBS Lab and BIQUA are leading the charge as the world's first quantum innovation research centre. GLOCYBS is committed to pioneering research and providing cutting-edge solutions to combat cyber threats.</p>
                        <div className='pt-8'>
                            <button className='bg-red-500 text-white rounded-md py-2 px-4 hover:bg-red-600 font-bold'>Explore Quantum World</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Quantum1;