import React from 'react';
import chatbot from '../images/chatbot-bgrem.png';

function Charlotte5() {
    const links = [
        { name: 'LEACH', url: '#' },
        { name: 'FLEA', url: '#' },
        { name: 'BEDBUG', url: '#' },
        { name: 'OPIUM POPPY', url: '#' },
    ];
    return (
        <div className='flex flex-col'>
            <div className="flex lg:flex-row flex-col-reverse h-4/5">
                <div className="flex justify-end md:items-center lg:w-1/3">
                    <img src={chatbot} alt="BIQUA" className="h-96 md:h-2/3" />
                </div>
                <div className='flex flex-col text-left md:justify-center justify-start pl-[5%] w-auto'>
                    <div className='md:text-4xl text-2xl pb-[1%]'>Meet <span className='text-orange-500 font-bold'>QUBISA</span></div>
                    <div className='md:text-4xl text-2xl font-bold pb-[3%]'>the Quantum and AI-based assistant at GLOCYBS</div>
                    <div className='md:text-lg text-sm w-[70%]'>QUBISA is dedicated to ensuring that every user operates in
                        a secure digital space leveraging advanced quantum and AI-driven technologies to protect against cyber
                        threats and safeguard your digital environment.</div>
                    <div className='pt-[2%]'>
                        <button className='bg-red-500 text-white rounded-md py-2 px-4 hover:bg-red-600 font-bold'>QUBISA</button>
                    </div>
                </div>
            </div>
            <div className="flex justify-center items-center h-1/5">
                <div className="bg-white text-black p-4 rounded-lg shadow-md w-full max-w-md md:max-w-lg lg:max-w-xl">
                    <p className="text-lg  md:text-xl font-semibold mb-4">Upcoming Innovations...</p>
                    <div className="flex space-x-2 justify-center flex-wrap">
                        {links.map((link, index) => (
                            <a
                                key={index}
                                href={link.url}
                                className="flex items-center space-x-2 px-0 py-1 bg-white text-black rounded-lg text-xs md:text-base"
                            >
                                <span className="text-cyan-500">G-</span>
                                <span>{link.name}</span>
                                {index < 3 && <span className="text-cyan-500">|</span>}
                            </a>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Charlotte5;