import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedinIn, faXTwitter, faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
    return (
        <div className="bg-white py-16 px-8">
            <div className='h-1 bg-black w-auto'></div>
            <div className='flex flex-col'>
                <div className='flex pt-[3%]'>
                    <div className='w-0 md:w-1/2'></div>
                    <div className='flex w-full md:w-1/2 space-x-8 md:space-x-12 md:justify-end justify-center text-xl md:text-3xl px-[1%]'>
                        <a href="https://in.linkedin.com/company/glocybs" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                        <a href="https://x.com/glocybs" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faXTwitter} />
                        </a>
                        <a href="https://www.facebook.com/GLOCYBS" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a href="https://www.instagram.com/glocybs" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="https://www.youtube.com/channel/UCgKomASzztDEy39FtxXvE5Q" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faYoutube} />
                        </a>
                    </div>


                </div>
                <div className="max-w-8xl pt-[3%] mx-auto flex flex-col lg:flex-row justify-between items-start">
                    {/* Left Section */}
                    <div className="lg:w-1/2 mb-12 lg:mb-0 mr-[13%] ml-[3%]">
                        <h2 className="text-2xl md:text-5xl font-semibold mb-6 text-left">Contact us</h2>
                        <p className="text-lg mb-8 text-left">
                            Be secure with GLOCYBS. We provide you with the most advanced defenses to protect your digital
                            space from online threats, assuring a secure and effortless online experience. GLOCYBS is always
                            evolving to keep up with new cyber threats. We believe in the power of teamwork.
                        </p>
                        <button className="flex justify-start bg-red-600 text-white py-3 px-6 rounded-md text-lg hover:bg-red-700 transition duration-200">
                            Reach out →
                        </button>
                    </div>

                    {/* Right Section */}
                    <div className="lg:w-1/2 grid grid-cols-1 md:grid-cols-3 gap-8 text-left">
                        <div className=''>
                            <h3 className="text-xl font-semibold mb-4">New to GLOCYBS?</h3>
                            <ul className='flex flex-col space-y-2 items-start text-md'>
                                <li><a href="https://www.google.com" className="text-gray-600 hover:text-black transition">About the Services</a></li>
                                <li><a href="https://www.google.com" className="text-gray-600 hover:text-black transition"> Explore BIQUA</a></li>
                                <li><a href="https://www.google.com" className="text-gray-600 hover:text-black transition">QuantumComputing</a></li>
                                <li><a href="https://www.google.com" className="text-gray-600 hover:text-black transition">Why Choose GLOCYBS?</a></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold mb-4">Company</h3>
                            <ul className='flex flex-col space-y-2 items-start text-md'>
                                <li><a href="https://www.google.com" className="text-gray-600 hover:text-black transition">About GLOCYBS</a></li>
                                <li><a href="https://www.google.com" className="text-gray-600 hover:text-black transition">Careers</a></li>
                                <li><a href="https://www.google.com" className="text-gray-600 hover:text-black transition">Events</a></li>
                                <li><a href="https://www.google.com" className="text-gray-600 hover:text-black transition">Newsroom</a></li>
                                <li><a href="https://www.google.com" className="text-gray-600 hover:text-black transition">Artificial intelligence</a></li>
                                <li><a href="https://www.google.com" className="text-gray-600 hover:text-black transition">G-QBS LAB</a></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold mb-4">Learn with GLOCYBS</h3>
                            <ul className='flex flex-col space-y-2 items-start text-md'>
                                <li><a href="https://www.google.com" className="text-gray-600 hover:text-black transition">BLOCKCHAIN Security</a></li>
                                <li><a href="https://www.google.com" className="text-gray-600 hover:text-black transition">Cybersecurity 101</a></li>
                                <li><a href="https://www.google.com" className="text-gray-600 hover:text-black transition">Your Threat Landscape</a></li>
                                <li><a href="https://www.google.com" className="text-gray-600 hover:text-black transition">Blogs</a></li>
                                <li><a href="https://www.google.com" className="text-gray-600 hover:text-black transition">Our Team</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* Footer Section */}
            <div className="max-w-7xl mx-auto mt-12 flex flex-col lg:flex-row justify-between items-start border-t pt-6">
                <p className="text-sm text-gray-600">© 2024 GLOCYBS. All rights reserved.</p>
                <div className="grid grid-cols-1 md:grid-cols-5 gap-y-2 sm:gap-y-0 sm:gap-x-6 mt-4 lg:mt-0">
                    <a href="https://www.google.com" className="text-gray-600 hover:text-black transition text-left">Contact Us</a>
                    <a href="https://www.google.com" className="text-gray-600 hover:text-black transition text-left">Privacy</a>
                    <a href="https://www.google.com" className="text-gray-600 hover:text-black transition text-left">Cookies</a>
                    <a href="https://www.google.com" className="text-gray-600 hover:text-black transition text-left">Terms of Use</a>
                    <a href="https://www.google.com" className="text-gray-600 hover:text-black transition text-left">Accessibility</a>
                </div>
            </div>

        </div>
    );
};

export default Footer;
