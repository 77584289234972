import React from 'react';
import data_breach_photo from '../images/data_breach_4-bgrem.png';

const DataBreach4 = () => {
    return (
        <div className="flex flex-col text-center items-center h-[80%] bg-slate-50">
            <div className='text-xl md:text-4xl font-bold pt-[4%] flex flex-col justify-end px-[5%] md:px-0 md:w-[35%]'>Expert, around-the-clock </div>
            <div className='text-md md:pt-[1%] pt-[3%] md:px-0 px-[5%]'>Always-on support with hands-on guidance from a team that’s always awake to protect you.</div>
            <div className="h-auto">
                <img src={data_breach_photo} alt="placeholder"  />
            </div>
            <div className='text-xl md:text-3xl lg:text-4xl font-bold md:px-0 pt-[1%] pb-[1%] md:w-[30%]'>Key capabilities of GLOCYBS Professional Services</div>
            <div className='text-md md:pt-[1%] pt-[3%] md:px-0 px-[5%] text-center'>
                {/* Our dedicated team provides continuous, hands-on support to ensure your protection around the clock. Our trained models constantly monitor for threats, while our advanced quantum tools are poised to deliver a robust response to any malicious activity. BIQUA represents a groundbreaking innovation in the cybersecurity landscape, offering unparalleled defense against black hat hackers. */}
            </div>
        </div>
    );
}

export default DataBreach4;