import './App.css';
import Navbar from './components/Navbar/Navbar';
import Biqua2 from './components/Biqua_2';
import Charlotte5 from './components/Charlotte_5';
import DataBreach4 from './components/DataBreach_4';
import MainContent from './components/MainContent_0';
import Services3 from './components/Services_3';
import Footer from './components/Footer';
import Quantum1 from './components/Quantum_1';

function App() {
  return (
    <div className="App w-full h-full overflow-hidden relative">
      <Navbar />
      <MainContent />
      <Quantum1 />
      <Biqua2 />
      <Services3 />
      <DataBreach4 />
      <Charlotte5 />
      <Footer />
    </div>
  );
}

export default App;
