import React from 'react';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { useState, useEffect } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import organisation from '../images/services/organisation.png';
import quantum from '../images/services/quantum.png';
import ai from '../images/services/database icon.png';

function Services3() {
    const [navSize, setNavSize] = useState("25px")

    const handleResize = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth < 640) {
            setNavSize('0px');
        } else if (screenWidth < 1024) {
            setNavSize('15px');
        } else {
            setNavSize('25px');
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="container mx-auto px-4 py-4 w-[100vw] md:w-[60vw]">
            <h1 className="text-2xl md:text-4xl font-extrabold text-left text-gray-900 mb-24 w-full md:w-2/3 pt-[4%]">Trust GLOCYBS to secure your future with the technology of tomorrow</h1>
            <div className=''>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={50}
                    autoplay={{
                        delay: 2000,  // 2 second
                        disableOnInteraction: false,  // Continue autoplay after user interaction
                    }}
                    breakpoints={
                        {
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            1424: {
                                slidesPerView: 3,
                                spaceBetween: 40,
                            }
                        }
                    }
                    navigation
                    style={{
                        "--swiper-navigation-color": "#fff",
                        "--swiper-navigation-size": navSize,
                    }}
                    // scrollbar={{ draggable: true }}
                    pagination={{ clickable: true }}
                    loop={true}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange
                >
                    <SwiperSlide>
                        <div className=''>
                            <div className="rounded-lg overflow-hidden shadow-lg text-left m-2">
                                <div className="relative bg-black h-80 flex items-center justify-center">
                                    <img className="max-h-full max-w-full" src={organisation} alt="Organisation" />
                                </div>
                                <div className="px-6 py-4">
                                    <div className="font-bold text-xl mb-2 text-gray-900 h-16">Secure your Organization</div>
                                    <div className='h-1 bg-gradient-to-r from-red-500 to-blue-500 mb-6'></div>
                                    <p className="text-gray-700 text-base h-16">
                                        Secure your organization with the future-proof solutions from GLOCYBS.
                                    </p>
                                </div>
                                <div className="px-6 pt-4 pb-2">
                                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full">
                                        Learn more <span className="ml-2">→</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className=''>
                            <div className="rounded-lg overflow-hidden shadow-lg text-left m-2">
                                <div className="relative bg-black h-80 flex items-center justify-center">
                                    <img className="max-h-full max-w-full" src={quantum} alt="Quantum" />
                                </div>
                                <div className="px-6 py-4">
                                    <div className="font-bold text-xl mb-2 text-gray-900 h-16">QuantumShield Services</div>
                                    <div className='h-1 bg-gradient-to-r from-red-500 to-blue-500 mb-6'></div>
                                    <p className="text-gray-700 text-base h-16">
                                        Utilizes adaptive intelligence and quantum to stop cyber threats.
                                    </p>
                                </div>
                                <div className="px-6 pt-4 pb-2">
                                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full">
                                        Learn more <span className="ml-2">→</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className=''>
                            <div className="rounded-lg overflow-hidden shadow-lg text-left m-2">
                                <div className="relative bg-black h-80 flex items-center justify-center">
                                    <img className="max-h-full max-w-full" src={ai} alt="AI" />
                                </div>
                                <div className="px-6 py-4">
                                    <div className="font-bold text-xl mb-2 text-gray-900 h-16">Blockchain Security</div>
                                    <div className='h-1 bg-gradient-to-r from-red-500 to-blue-500 mb-6'></div>
                                    <p className="text-gray-700 text-base h-16">
                                        Strengthens your blockchain with AI-powered audits.
                                    </p>
                                </div>
                                <div className="px-6 pt-4 pb-2">
                                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full">
                                        Learn more <span className="ml-2">→</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
}

export default Services3;