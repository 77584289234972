import React from 'react';
import biqua_logo from '../images/biqua-logo-bgrem.png';
import laptop_bg_2 from '../images/biqua_bg_2.png';
import mobile_bg_2 from '../images/biqua_bg_mobile_2.png';

function Biqua2() {
    return (
        <div className='flex flex-col-reverse md:flex-row h-[100%] bg-cover bg-center'
            style={{
                backgroundImage: `url(${window.innerWidth >= 768 ? (window.innerWidth >= 1024 ? laptop_bg_2 : laptop_bg_2) : mobile_bg_2
                    })`,
            }}
        >

            <div className='flex flex-col md:w-1/2 h-1/2 md:h-auto pb-[30%] md:py-[15%] space-y-1 md:space-y-4 justify-center lg:items-start text-left text-white px-[8%]'>
                <h3 className='text-3xl md:text-5xl '>BIQUA Trio of AI</h3>
                <h3 className='text-[160%] md:text-5xl'>Blockchain, and Quantum</h3>
                <div className='flex flex-row'>
                    <h1 className='text-3xl md:text-5xl font-extrabold'>By GLO</h1><p className='text-cyan-500 text-3xl md:text-5xl font-bold'>CYBS</p>
                </div>
                <p className="text-sm md:text-2xl pt-[4%]">GLOCYBS BIQUA: The Quantum lash AI tools</p>
                <div className='flex pt-4'>
                    <button className="bg-gradient-to-r from-purple-400 via-pink-500 to-orange-600 text-white font-bold p-2 rounded-md transition duration-300 justify-end">
                        Explore BIQUA
                    </button>
                </div>

            </div>
            <div className='flex h-1/2 md:h-auto justify-center items-center md:w-1/2 py-[10%]'>
                <img src={biqua_logo} alt="BIQUA" className="h-auto" />
            </div>

        </div>
    );
};

export default Biqua2;