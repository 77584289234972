import React from 'react';

const Services = ({ onMouseEnter, onMouseLeave }) => {
    return (
        <div className="md:absolute z-20 md:bg-white md:p-8 md:rounded-lg md:shadow-lg md:w-[1200px] md:-left-80 overflow-x-auto" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {/* <!-- Heading --> */}
            <div className="text-center mb-0">
                <h2 className="text-lg font-bold text-red-500">All Services</h2>
            </div>
            <div className="flex justify-start">
                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full mb-2">
                    Explore Platform <span className="ml-2">→</span>
                </button>
            </div>

            {/* <!-- Divider --> */}
            <div className="border-b-2 border-red-500 mb-8"></div>

            {/* <!-- Grid --> */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 text-left">

                {/* <!-- Card 1 --> */}
                <div>
                    <a href="https://www.google.com" className="font-bold text-gray-800 mb-2">AI DEFENSE</a>
                    <p className="text-gray-600">Utilizes adaptive intelligence and advanced AI to forecast, identify, and stop cyber threats.</p>
                </div>

                {/* <!-- Card 2 --> */}
                <div>
                    <a href="https://www.google.com" className="font-bold text-gray-800 mb-2">BLOCKCHAIN SECURITY</a>
                    <p className="text-gray-600">Strengthens your blockchain with AI-powered audits and multi-layered security, ensuring data integrity and protection from threats</p>
                </div>

                {/* <!-- Card 3 --> */}
                <div>
                    <a href="https://www.google.com" className="font-bold text-gray-800 mb-2">ORGANIZATIONAL SECURITY</a>
                    <p className="text-gray-600">Provides comprehensive organizational security by protecting your data, devices, and employees against internal and external attacks.</p>
                </div>

                {/* <!-- Card 4 --> */}
                <div>
                    <a href="https://www.google.com" className="font-bold text-gray-800 mb-2">WEBSITE SECURITY</a>
                    <p className="text-gray-600">Offers comprehensive website security with multi-layered protection, and 24/7 threat monitoring.</p>
                </div>

                {/* <!-- Card 5 --> */}
                <div>
                    <a href="#" className="font-bold text-gray-800 mb-2">DATABASE SECURITY</a>
                    <p className="text-gray-600">Deliver powerful database security solutions that protect sensitive information with enhanced protection.</p>
                </div>

                {/* <!-- Card 6 --> */}
                <div>
                    <a href="https://www.google.com" className="font-bold text-gray-800 mb-2">DATA LOSS PROTECTION (DLP)</a>
                    <p className="text-gray-600">Protect your organization with our complete data loss prevention service, which protects essential information from unauthorized access and accidental breaches.</p>
                </div>

                {/* <!-- Card 7 --> */}
                <div>
                    <a href="https://www.google.com" className="font-bold text-gray-800 mb-2">CYBER SECURITY INSURANCE BROKERAGE</a>
                    <p className="text-gray-600">Secure your business with our expert cybersecurity insurance brokerage, designed to protect against digital risks and financial losses. </p>
                </div>

                {/* <!-- Card 8 --> */}
                <div>
                    <a href="https://www.google.com" className="font-bold text-gray-800 mb-2">MANAGED DETECTION AND RESPONSE</a>
                    <p className="text-gray-600">Stay secure 24/7 with Managed Detection and Response, providing expert threat detection and immediate response.</p>
                </div>

                {/* <!-- Card 9 --> */}
                <div>
                    <a href="https://www.google.com" className="font-bold text-gray-800 mb-2">IDENTITY AND ACCESS MANAGEMENT (IAM)</a>
                    <p className="text-gray-600">Securely manage who accesses what, when, and how with our Identity and Access Management (IAM) service.</p>
                </div>

                {/* <!-- Card 10 --> */}
                <div>
                    <a href="https://www.google.com" className="font-bold text-gray-800 mb-2">DIGITAL FORENSICS</a>
                    <p className="text-gray-600">Investigating and interpreting digital footprints to secure your operations with our digital forensic service.</p>
                </div>
            </div>
        </div>

    )
}

export default Services;